<template>
  <v-row
    style="padding:0; margin-top:1%; font-family: 'Montserrat'; sans-serif; font-size:18px;"
  >
    <v-col cols="12" style="background-color: red">
      <v-sheet>
        <h3 style="font-size: 22px"><b>INFORMACIÓN DE CLIENTE</b></h3>
      </v-sheet>
    </v-col>
    <v-col xl="3" lg="3" md="6" cols="12" style="background-color: red">
      <v-sheet>
        <p><b>Nombre:</b></p>
        <p>Jaun Flores - Tap Velada</p>
      </v-sheet>
    </v-col>
    <v-col xl="3" lg="3" md="6" cols="12" style="background-color: blue">
      <v-sheet>
        <p><b>Correo:</b></p>
        <p>reservaciones@hoturmex.com</p>
      </v-sheet>
    </v-col>
    <v-col xl="3" lg="3" md="6" cols="12" style="background-color: red">
      <v-sheet>
        <p><b>Teléfono:</b></p>
        <p>348-125-2554</p>
      </v-sheet>
    </v-col>
    <v-col xl="6" lg="6" md="6" cols="12" style="background-color: blue">
      <v-sheet>
        <p><b>Comentarios:</b></p>
        <p>
          Habitación con vista a la playa y preferentemente en primero o segundo
          nivel.
        </p>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400&display=swap");
</style>